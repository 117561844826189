const ACTION_ITEM_STATUSES = {
  OPEN: "open",
  READY_FOR_REVIEW: "ready for review",
  REJECTED: "rejected",
  CANCELED: "canceled",
  CLOSED: "closed",
  NEW: "new",
};

export default ACTION_ITEM_STATUSES;
